
*{
    margin: 0;
    padding: 0;
}
.wrapper{
    
    .headBox{
        background-color: rgb(60,188,167);
        height: 400px;
        .content{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            z-index: 9;
            overflow: hidden;
            .head{
                display: flex;
                align-items: center;
                margin-top: 20px;
                .logo{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 24px;
                    font-weight: bold;
                    img{
                        width: 60px;
                    }
                }
                .nav{
                    margin-left: 150px;
                    span{
                        font-size: 20px;
                        margin-left: 50px;
                        color: #fff;
                        cursor: pointer;
                        a{
                            text-decoration: none;
                            color: #fff;
                        }
                    }
                }
            }
            .headContent{
                display: flex;
                margin-top: 40px;
                justify-content: space-between;
                .swiper{
                    width: 500px;
                    overflow: hidden;
                    color: #fff;
                    margin-left: 50px;
                    .swiper-slide{
                        >p{
                            font-size: 30px;
                            margin-bottom: 10px;
                            font-weight: bold;
                        }
                        .word{
                            line-height: 30px;
                            font-size: 18px;
                        }
                    }
                }
                .codeBox{
                    display: flex;
                    align-items: center;
                    .item{
                        margin-left: 20px;
                        img{
                            width: 120px;
                            height: 120px;
                            display: block;
                        }
                        p{
                            color: #fff;
                            text-align: center;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    .system{
        background-color: rgb(242,242,242);
        max-height: 530px;
        .content{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            background-color: #fff;
            text-align: center;
            top: -80px;
            position: relative;
            overflow: hidden;
            z-index: 999;
            line-height: 30px;
            font-size: 18px;
            padding-bottom: 50px;
            >p{
                margin:50px 0 30px;
                font-size: 28px;
                font-weight: bold;
            }
            .note{
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }
    }
    .power{
        .content{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            background-color: #fff;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 999;
            line-height: 30px;
            font-size: 18px;
            padding-bottom: 50px;
            >p{
                margin:50px 0 30px;
                font-size: 28px;
                font-weight: bold;
            }
            .note{
                margin-top: 10px;
            }
            .imgBox{
                margin-top: 40px;
                display: flex;
                justify-content: center;
                img{
                    margin-right: 20px;
                    width: 320px;
                }
            }
        }
    }
    .skill{
        
        background-color: rgb(242,242,242);
        .content{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            // background-color: #fff;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 999;
            line-height: 30px;
            font-size: 18px;
            padding-bottom: 50px;
            >p{
                margin:50px 0 30px;
                font-size: 28px;
                font-weight: bold;
            }
            .note{
                margin-top: 10px;
            }
            .itemBox{
                overflow: hidden;
                margin-top: 40px;
                .item{
                    width: calc((100% - 20px) / 3);
                    height: 200px;
                    background-color: rgb(0,204,153);
                    float: left;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    &:nth-of-type(3n){
                        margin-right:0;
                    }
                }
            }
        }
    }
    .practive{
        .content{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            background-color: #fff;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 999;
            line-height: 30px;
            font-size: 18px;
            padding-bottom: 50px;
            >p{
                margin:50px 0 30px;
                font-size: 28px;
                font-weight: bold;
            }
            .note{
                margin-top: 10px;
            }
            .imgBox{
                margin-top: 40px;
                display: flex;
                justify-content: center;
                img{
                    margin-right: 20px;
                    width: 320px;
                }
            }
        }
    }
    .footer{
        background-color: rgb(242,242,242);
        text-align: center;
        padding:30px 0;
        >a{
            text-decoration: none;
            display: block;
            color: #333;
            margin-bottom: 10px;
            font-size: 15px;
        }
        .codeBox{
            display: flex;
            justify-content: center;
            .item{
                margin-right: 15px;
                &:nth-of-type(3){
                    margin-right: 0;
                }
                img{
                    width: 100px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }
    .our{
        background-color: rgb(242,242,242);
        .content{
            box-sizing: border-box;
            width: 1200px;
            margin: 0 auto;
            position: relative;
            background-color: #fff;
            text-align: center;
            top: -80px;
            position: relative;
            overflow: hidden;
            z-index: 999;
            line-height: 24px;
            font-size: 14px;
            padding-bottom: 50px;
            display: flex;
            padding: 80px 50px;
            justify-content: space-between;
            .menu{
                width: 100px;
                text-align: left;
                margin-top: 50px;
                >div{
                    cursor: pointer;
                    padding-bottom: 8px;
                    margin-bottom: 20px;
                    border-bottom: 1px  solid rgb(219,219,219);
                    font-size: 18px;
                    color: rgb(107,107,107);
                }
            }
            .body{
                width: calc(100% - 150px);
                text-align: left;
                .block{
                    margin-bottom: 50px;
                    >p{
                        color: rgb(60,188,167);
                        padding-bottom: 8px;
                        border-bottom: 1px  solid rgb(219,219,219);
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    .word{
                        p{
                            margin-bottom: 5px;
                        }
                        div{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}